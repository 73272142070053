import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import DOMPurify from 'dompurify';
import Helmet from "react-helmet"

const APOLLO_QUERY_POSTS = gql`
  {
  	  posts {
	    nodes {
	      title
	      featuredImage {
	        node {
	          mediaItemUrl
	        }
	      }
	      postId
	    }
	  }

  }
`

const sanitizer = DOMPurify.sanitize;
let content = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;

const IndexPage = () => {

	return (
	  <Layout>
	    <SEO title="Blog" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Blog from copywriter The Sharpener</title>
            <meta name="title" content="Blog from copywriter The Sharpener" />
            <meta name="description" content="I’m an experienced Buckinghamshire-based copywriter specialising in blogs, websites and tone of voice. Read my blog to learn more" />
        </Helmet>

	    <Grid container className="main" style={{ display:'flex', alignItems:'flex-start' }}>

	    	<Grid container className="blogpage">
	    		<h1>Blog</h1>

		    		<Grid container className="blog_text">

			    		 <Query query={APOLLO_QUERY_POSTS}>

		                      {({data}) => {

			                      if(data){
			                        
			                         content = data.posts.nodes.map((item, index) => {

			                         	{/*console.log(item)*/}

			                            return (

			                            	<Link key={index} className="blog_link" to={`/blog_post/?id=${item.postId}`}>
				                            	<Grid container className="blog_row" style={{ padding:'0 0 0px 0' }}>

				                            	<Grid item className="blog_thumbnail" style={{ backgroundImage: `url("${item.featuredImage.node.mediaItemUrl}")` }}></Grid>

												<Grid item className="blog_title">{item.title}</Grid>

												</Grid>
											</Link>

			                            )

			                          })

			                          {/*console.log(data)*/}
			                      }

		                      return content

		                    }}

		                </Query>
			    		
		    		</Grid>

	    	</Grid>

	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
